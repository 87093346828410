$(function () {
    $('#hamburger-button').click(
        function () {
            $("#menu-open-layer").css('width', '100%');
            $("#hamburger-menu").animate({right: 0}, 500);
            $("#page-wrap, header").animate({left: -250}, 500);
        }
    );
    $('#hm-close-button').click(
        function () {
            $("#hamburger-menu").animate({right: -250}, 500);
            $("#page-wrap, header").animate({left: 0}, 500, function () {
                $("#menu-open-layer").css('width', 0);
            });
        }
    );
});