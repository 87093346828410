$(window).bind("load resize", function () {
    $("#left-side").css("height", "auto");

    var ww = $(window).width();

    if (ww > 768) {
        var nh = $(window).outerHeight() - $('header').outerHeight();
        var lsh = $("#left-side").height();
        if (nh > lsh) {
            $("#left-side").height(nh);
        }
    }
});