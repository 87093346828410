/*function changeTopNav() {
    if ($(this).scrollTop() > 2) {
        $("#logo").hide();
        $("#logo-scrolled").show();
        $('body, #page-wrap').addClass('scrolled');
    } else {
        $("#logo-scrolled").hide();
        $("#logo").show();
        $('body, #page-wrap').removeClass('scrolled');
    }
}

$(window).scroll(function () {
    if(scroll==1){
    changeTopNav();
    }
});

$(function () {
    if(scroll==1){
        changeTopNav();
    }
});*/