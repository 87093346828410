$(function () {
    $('.bottom-slider .owl-carousel').each(function() {
    $(this).owlCarousel({
        autoplay: true,
        loop: true,
        margin: 0,
        nav: true,
        items: 1,
        navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>', '<i class="fa fa-chevron-right" aria-hidden="true"></i>']
    })
    });

    $('#related-slider .owl-carousel').owlCarousel({
        autoplay: true,
        loop: true,
        margin: 0,
        nav: true,
        items: 1,
        navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>', '<i class="fa fa-chevron-right" aria-hidden="true"></i>']
    })


    $('#ps-tab-1-tab-carousel').owlCarousel({
        autoplay: true,
        loop: true,
        margin: 0,
        nav: false,
        dots: true,
        items: 1
    })

});